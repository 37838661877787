import React, { useEffect } from 'react'

import { Box, Button, Typography, useTheme } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

import ReactPixel from 'react-facebook-pixel'

// Own imports
import { Pedido } from 'types/api'
import { history } from '_helpers'
import { useRouter } from 'utils'
import { useEvento } from 'hooks-querys'

type CardPaymentSuccessProps = {
  order: Pedido
}

const CardPaymentSuccess = ({ order }: CardPaymentSuccessProps) => {
  const theme = useTheme()
  const router = useRouter()
  const eventoId = order?.carrinhoItem[0].lote?.eventoId

  const { data: evento } = useEvento(eventoId)

  useEffect(() => {
    const isPaymentSuccessPath =
      router?.location?.pathname?.includes('/payment-success')
    const hasFacebookPixelId = evento?.estabelecimento?.facebookPixelId

    if (order?.statusPedido?.finalizado === true && isPaymentSuccessPath) {
      if (hasFacebookPixelId) {
        ReactPixel.pageView()
      }
    }
  }, [router, evento?.estabelecimento?.facebookPixelId])

  useEffect(() => {
    if (order?.statusPedido?.finalizado === true) {
      history.push(`/pedido/${order?.id}/payment-success`)
    }
  }, [order])

  return (
    <Box
      minHeight={300}
      marginTop={2}
      alignItems="center"
      justifyContent={'space-between'}
      display="flex"
      flexDirection={'column'}
      padding={2}
    >
      <Typography variant="h6">
        Pedido nº {order.id} -{' '}
        {format(new Date(order.dataHora), `dd'/'MM'/'yyyy 'às' kk':'mm`, {
          locale: brLocale
        })}
      </Typography>
      <Box>
        <CheckCircleIcon
          style={{ color: theme.palette.success.dark, fontSize: 55 }}
        />
        <Typography gutterBottom variant="h5">
          {' '}
          <strong>O pedido foi finalizado com sucesso!</strong>
        </Typography>
        <Typography variant="body2">
          Os detalhes do pedido foram enviados no seu email e os ingressos estão
          disponíveis no seu perfil 👇
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push(`/approot/perfil/tickets/ativos`)}
      >
        Ver meus ingressos
      </Button>
    </Box>
  )
}

export default CardPaymentSuccess
