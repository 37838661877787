import {
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { DialogUserNecessaryDataToPurchase } from 'components'
import {
  useCarrinhoItensEvento,
  useDiscountCoupon,
  useEvento,
  useOrder
} from 'hooks-querys'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter, validateUserNecessaryData } from 'utils'
import { calculaValorAPagar } from 'utils/purchaseCalculate'
import {
  OrderSummary,
  PaymentMethods,
  PurchaseApplicationForm
} from './components'
import { areAllRequiredItemsFilled } from './components/PurchaseApplicationForm/purchaseApplicationFormUtils'

import ReactPixel from 'react-facebook-pixel'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('md')]: { padding: theme.spacing(4, 0, 10, 0) }
  },
  subtitle: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
  },
  paper: {
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2)
      // minHeight: '60vh'
    },
    [theme.breakpoints.down('sm')]: {}
  }
}))

const OrderPayment = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const router = useRouter()
  const eventoId = router.match.params.eventoId
  const pedidoId = router.match.params.pedidoId
  const isOrderGenerated = !!pedidoId
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const {
    data,
    isError,
    isLoading: isLoadingCarrinhoItens
  } = useCarrinhoItensEvento(eventoId)
  const carrinhoItens =
    data &&
    data.filter(
      (carrinhoItem) =>
        carrinhoItem.carrinhoItem.id > 0 &&
        carrinhoItem.carrinhoItem.quantidade > 0
    )
  const dispatch = useDispatch()
  const { user, loggingIn } = useSelector((state) => state.authentication)

  const totalValue = calculaValorAPagar(
    carrinhoItens || [],
    (discountCoupon && discountCoupon.carrinhoItens) || [],
    discountCoupon && discountCoupon.cupomDesconto
  )
  const {
    data: order,
    isLoading: isLoadingOrder,
    isFetching,
    error,
    refetch
  } = useOrder(pedidoId && Number(pedidoId), undefined)

  const userHasSomeItemInTheBasket = data?.some(
    (carrinhoItem) => carrinhoItem.carrinhoItem.quantidade > 0
  )
  const someItemNeedApplicationForm =
    userHasSomeItemInTheBasket &&
    data?.some(
      (carrinhoItem) =>
        carrinhoItem?.carrinhoItem?.lote?.exigeInscricao === true
    )
  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.purchaseApplicationFormItens
  )

  const { data: evento, isLoading: isLoadingEvent } = useEvento(
    eventoId || order?.carrinhoItem?.[0]?.lote?.eventoId
  )
  useEffect(() => {
    if (eventoId) {
      !isMobile && window.scrollTo(0, 0)
    }
    if (isOrderGenerated && order) {
      !isMobile && window.scrollTo(0, 0)
    }
  }, [eventoId, order, isOrderGenerated])

  useEffect(() => {
    if (!isOrderGenerated && !loggingIn && user && user.id) {
      validateUserNecessaryData(dispatch, user)
    }
  }, [user, loggingIn])

  // Check if all required items are filled
  const allRequiredItemsFilled = areAllRequiredItemsFilled(
    purchaseApplicationFormItens
  )

  useEffect(() => {
    if (evento?.estabelecimento?.facebookPixelId) {
      ReactPixel.trackCustom('Add to cart', null)
    }
  }, [evento?.estabelecimento?.facebookPixelId])

  return (
    <Container maxWidth="lg" className={classes.root}>
      <DialogUserNecessaryDataToPurchase />
      {isLoadingCarrinhoItens || isLoadingOrder || isLoadingEvent ? (
        <Box marginY={4}>
          <Typography gutterBottom variant="h4" align="center">
            Carregando...
          </Typography>
          <Box marginTop={2}>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        evento && (
          <Grid
            container
            spacing={isMobile && !someItemNeedApplicationForm ? 0 : 2}
            direction={
              isMobile &&
              (!someItemNeedApplicationForm || allRequiredItemsFilled)
                ? 'column-reverse'
                : 'row'
            }
          >
            {someItemNeedApplicationForm && (
              <Grid item sm={12} md={allRequiredItemsFilled ? 12 : 8}>
                <Typography
                  align="left"
                  gutterBottom
                  variant="h5"
                  className={classes.subtitle}
                >
                  <b>Formulário de inscrição</b>
                </Typography>
                <Paper className={classes.paper}>
                  <PurchaseApplicationForm eventoId={eventoId} />
                </Paper>
              </Grid>
            )}
            {(!someItemNeedApplicationForm || allRequiredItemsFilled) && (
              <Grid item sm={12} md={8}>
                <Typography
                  align="left"
                  gutterBottom
                  variant="h5"
                  className={classes.subtitle}
                >
                  <b>
                    {isOrderGenerated
                      ? 'Status do pedido'
                      : 'Formas de pagamento'}
                  </b>
                </Typography>
                <Paper className={classes.paper}>
                  <PaymentMethods
                    evento={evento}
                    isOrderGenerated={isOrderGenerated}
                    order={order}
                    totalValue={totalValue}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item sm={12} md={4}>
              <Typography
                align="left"
                className={classes.subtitle}
                gutterBottom
                variant="h5"
              >
                <b>Resumo do pedido</b>
              </Typography>
              <Paper className={classes.paper}>
                <OrderSummary
                  evento={evento}
                  order={order}
                  isOrderGenerated={isOrderGenerated}
                />
              </Paper>
            </Grid>
          </Grid>
        )
      )}
    </Container>
  )
}

export default OrderPayment
